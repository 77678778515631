import { useTranslation } from 'react-i18next';
import DownloadButton from './DownloadButton';
import ZoomInput from './ZoomInput';
import Tag from '../Tag/Tag';
import { Icons } from '@/assets';
import { DocumentProperties, FileProps } from '@/services/api/apiValidation';

// type DebounceInputProps = {
//   pageCount: number;
//   setDebouncedValue: (value: PageNumber) => void;
//   debouncedValue: number;
// };
// const DebounceInput = ({ pageCount, setDebouncedValue, debouncedValue }: DebounceInputProps) => {
//   const [inputValue, setInputValue] = useState(1);
//   const [debounce, setDebounce] = useState(true);

//   useEffect(() => {
//     setInputValue(debouncedValue + 1);
//     setDebounce(false);
//   }, [debouncedValue]);

//   const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
//     const int = parseInt(event.target.value);
//     setDebounce(true);
//     return setInputValue(int);
//   };

//   useEffect(() => {
//     let timeoutId: NodeJS.Timeout;

//     if (debounce) {
//       timeoutId = setTimeout(() => {
//         if (isNaN(inputValue)) return { number: 0, id: Math.random(), offset: 5 };
//         if (inputValue < 0) return setDebouncedValue({ number: 0, id: Math.random(), offset: 5 });
//         if (inputValue > (pageCount ?? 0))
//           return setDebouncedValue({
//             number: pageCount - 1,
//             id: Math.random(),
//             offset: 5,
//           });
//         setDebouncedValue({ number: inputValue - 1, id: Math.random(), offset: 5 });
//       }, 500);
//     }
//     return () => clearTimeout(timeoutId);
//   }, [debounce, inputValue, pageCount, setDebouncedValue]);

//   return (
//     <input
//       type="number"
//       className="w-7 text-xs rounded text-center [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none focus:outline-none ring-1 ring-qura-neutral-silver hover:ring-qura-neutral-jet focus:ring-qura-neutral-balanced mr-1 "
//       min={1}
//       max={pageCount}
//       value={inputValue}
//       onChange={handleInputChange}
//       data-np-intersection-state="hidden"
//       autoComplete="off"
//     />
//   );
// };




type PDFReaderTopBarProps = {
  scale: number;
  setScale: (value: number) => void;
  documentProperties?: DocumentProperties;
  documentId: string;
  documentUnitId: string;
  fileProps?: FileProps;
};
export const PDFReaderTopBar = ({
  scale,
  setScale,
  documentProperties,
  documentId,
  documentUnitId,
  fileProps,
}: PDFReaderTopBarProps) => {
  const { t } = useTranslation();

  const legalId = documentProperties?.legalId ?? '-';

  return (
    <div className="flex justify-between items-center border-b px-4 py-3 gap-6">
      <div className="flex flex-1 flex-shrink overflow-hidden">
        <Tag className="flex overflow-hidden flex-shrink md">
          <Icons.Book className="mr-2 mb-[1px] flex-shrink-0" />
          <p
            className="font-medium overflow-hidden text-ellipsis whitespace-nowrap flex-shrink"
            title={legalId}>
            {legalId}
          </p>
        </Tag>
      </div>
      {documentProperties ? (
        <>
          <div className="flex flex-shrink-0">
            {/* <DebounceInput
              debouncedValue={3}
              setDebouncedValue={setScrollToPage}
              pageCount={documentProperties.pageCount}
            /> */}
            <p className="text-xs"> / {documentProperties.pageCount}</p>
          </div>
          <div className="flex items-center relative gap-1 md:gap-2 flex-1 flex-shrink-0 justify-end">
            <ZoomInput scale={scale} setScale={setScale} />
            <DownloadButton
              documentId={documentId}
              documentUnitId={documentUnitId}
              fileModifications={fileProps}
              identifier={documentProperties?.legalId ?? documentProperties.title}
            />
          </div>
        </>
      ) : (
        <p className="text-sm animate-pulse">{t('common.loading')}</p>
      )}
    </div>
  );
};
