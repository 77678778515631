import { useQuery } from '@tanstack/react-query';
import { doc, getDoc } from 'firebase/firestore';
import { z } from 'zod';
import { db } from '../../firebase/firebaseConfig';
import { convertSnakeToCamelCaseObject } from '@/utils/snakeToCamelCase';

export type TagNode = {
  tagId: string;
  label: string;
  description: string | null;
  weight: number;
  subNodes: TagNode[];
};

const tagNodeSchema = z
  .object({
    tag_id: z.string(),
    label: z.string(),
    description: z.string().nullable().default(null),
    weight: z.number().int(),
    sub_nodes: z
      .lazy(() => tagNodeSchema.array())
      .nullable()
      .default([]),
  })
  .transform(convertSnakeToCamelCaseObject) as unknown as z.ZodType<TagNode>;

const availableFiltersSchema = z
  .object({
    tag_nodes: tagNodeSchema.array(),
  })
  .transform(convertSnakeToCamelCaseObject);

export type AvailableFilters = z.infer<typeof availableFiltersSchema>;

async function getAvailableFilterTagsRequest(dataRole: string): Promise<AvailableFilters | null> {
  const docRef = doc(db, 'dataRoles', dataRole);
  const document = await getDoc(docRef);
  if (!document.exists()) {
    return null;
  }

  const data = document.data();
  return availableFiltersSchema.parse(data.tag_tree);
}

export const useAvailableFilterTags = (dataRole: string) => {
  return useQuery({
    queryKey: ['filter_tags', dataRole],
    queryFn: () => getAvailableFilterTagsRequest(dataRole),
    enabled: dataRole !== null,
  });
};
