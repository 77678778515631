import { MAX_CHUNKS_PER_DOCUMENT, MAX_DOCUMENTS_PER_SEARCH } from '../../constants';
import { useSearchStore } from '../searchStore';
import { SearchParams, SearchResult } from '@/types/api';
import { TagNode } from '@/services/api/actions/getAvailableFilterTags';

export const setQuery = (query: string) => {
  useSearchStore.setState(
    (state) => {
      state.currentParams.query = query;
      state.currentParams.searchMode.noAiFilter = false;
    },
    false,
    'searches/set-query',
  );
};

export const setParams = (params: SearchParams) => {
  useSearchStore.setState(
    (state) => {
      state.currentParams = params;
    },
    false,
    'searches/set-params',
  );
};

export const setActiveDataRole = (dataRole: string) => {
  useSearchStore.setState(
    (state) => {
      state.currentParams.dataRole = dataRole;
    },
    false,
    'searches/set-params',
  );
};

export const loadOldSearch = (
  searchId: string,
  result: SearchResult,
  submittedParams: SearchParams,
) => {
  useSearchStore.setState(
    (state) => {
      state.searches[searchId] = {
        ws: null,
        submittedParams,
        results: result,
      };
    },
    false,
    'searches/set-params',
  );
};

export const setFilterAfterYear = (startYear: number | null) => {
  useSearchStore.setState(
    (state) => {
      state.currentParams.searchMode.noAiFilter = false;
      state.currentParams.filter.afterDate = startYear ? [startYear, 1, 1] : null;
    },
    false,
    'searches/set-filter-start-year',
  );
};

export const setFilterBeforeYear = (endYear: number | null) => {
  useSearchStore.setState(
    (state) => {
      state.currentParams.searchMode.noAiFilter = false;
      state.currentParams.filter.beforeDate = endYear ? [endYear, 12, 31] : null;
    },
    false,
    'searches/set-filter-end-year',
  );
};

export const resetFilter = () => {
  useSearchStore.setState(
    (state) => {
      state.currentParams.searchMode.noAiFilter = true;
      state.currentParams.filter = {
        tagIds: [],
        documentIds: [],
        afterDate: null,
        beforeDate: null,
      };
    },
    false,
    'searches/reset-filter',
  );
};

export const clearParams = () => {
  useSearchStore.setState(
    (state) => {
      state.currentParams = {
        query: '',
        maxChunksPerDocument: MAX_CHUNKS_PER_DOCUMENT,
        maxDocuments: MAX_DOCUMENTS_PER_SEARCH,
        searchMode: {
          params: {},
          noAiFilter: false,
          noChunks: false,
        },
        filter: {
          tagIds: [],
          documentIds: [],
          afterDate: null,
          beforeDate: null,
        },
        dataRole: state.currentParams.dataRole,
      };
    },
    false,
    'searches/clear-params',
  );
};

export const addTag = (tag: TagNode) => {
  const childIds = new Set<string>();
  const tagQueue = [...tag.subNodes];
  while (tagQueue.length > 0) {
    const tag = tagQueue.pop()!;
    if (childIds.has(tag.tagId)) continue;
    childIds.add(tag.tagId);
    tagQueue.push(...tag.subNodes);
  }

  const anyChildSelected = useSearchStore
    .getState()
    .currentParams.filter.tagIds.some((tagId) => childIds.has(tagId));

  if (anyChildSelected) {
    removeTags([tag.tagId]);
  } else {
    useSearchStore.setState(
      (state) => {
        state.currentParams.searchMode.noAiFilter = false;
        state.currentParams.filter.tagIds.push(tag.tagId);
      },
      false,
      'searches/add-filter-tag',
    );
  }
};

export const removeTags = (tagIds: Set<string> | string[]) => {
  tagIds = Array.isArray(tagIds) ? new Set(tagIds) : tagIds;
  useSearchStore.setState(
    (state) => {
      state.currentParams.searchMode.noAiFilter = false;
      state.currentParams.filter.tagIds = state.currentParams.filter.tagIds.filter(
        (tagId) => !tagIds.has(tagId),
      );
    },
    false,
    'searches/remove-tags',
  );
};
