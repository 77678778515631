import { PageProps, Rect } from "@/services/api/apiValidation";
import { KeywordHighlight, LinkHighlight, TextHighlight } from "./Highlights";


export const Overlays = ({
    pageModifications,
    zoom,
}: {
    pageModifications: PageProps;
    zoom: number;
}) => {

    const highlights = pageModifications?.highlights;

    const applyZoom = (rect: Rect): Rect => {
        return {
            x0: rect.x0 * zoom,
            x1: rect.x1 * zoom,
            y0: rect.y0 * zoom,
            y1: rect.y1 * zoom,
        }
    }

    const textHighlightRects = highlights?.filter((highlight) => highlight.type === 'text').map((highlight) => applyZoom(highlight.rect));
    const keywordHighlightRects = highlights?.filter((highlight) => highlight.type === 'keyword').map((highlight) => applyZoom(highlight.rect));
    const linkHighlightRects = highlights?.filter((highlight) => highlight.type === 'link').map((highlight) => applyZoom(highlight.rect));


    return (
        <>
            {textHighlightRects && textHighlightRects.map((rect) => (
                <TextHighlight rect={rect} />
            ))}
            {keywordHighlightRects && keywordHighlightRects.map((rect) => (
                <KeywordHighlight rect={rect} />
            ))}
            {linkHighlightRects && linkHighlightRects.map((rect) => (
                <LinkHighlight rect={rect} />
            ))}
        </>
    )
}