/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, MouseEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icons } from '@/assets/index.ts';
import {
  DataBaseInfoSource,
  DataRoleDBInfo,
  useDatabaseInfo,
} from '@/services/api/actions/getDatabaseInfo.ts';
import { useUserDataStore } from '@/services/firebase/state/userDataStore';

interface IDatabaseInfoModal {
  onClose: () => void;
}
const DatabaseInfoModal: FC<IDatabaseInfoModal> = ({ onClose }) => {
  const userData = useUserDataStore((state) => state.data);
  const dataRoles = userData?.storedUser.dataRoles.map((role) => role.roleName);
  const { isFetching, data: dbInfo } = useDatabaseInfo(dataRoles ?? []);
  console.log('dbInfo', dbInfo);
  const { t } = useTranslation();

  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    return () => document.body.classList.remove('overflow-hidden');
  }, []);

  return (
    <>
      <div className="fixed z-20 inset-0 flex items-center justify-center">
        <div className="fixed inset-0 bg-black/50" onClick={onClose} />
        <div className="flex flex-col w-[800px] h-[600px] max-h-[90%] max-w-[90%] px-7 py-7 bg-white rounded-[28px] shadow-lg relative">
          <h2 className=" text-lg font-medium">{t('databaseInformation.databaseInformation')}</h2>
          <div className="flex flex-col flex-1 overflow-y-auto">
            {isFetching ? (
              <div className="flex flex-1 justify-center items-center">
                <p className="tracking-wide animate-pulse">{t('common.loading')}</p>
              </div>
            ) : dbInfo ? (
              <DataRoleList dbInfo={dbInfo.data_roles} />
            ) : (
              <p className="text-qura-red text-m">{t('errors.unknown')}</p>
            )}
          </div>

          <button
            className="absolute top-6 right-7 text-gray-500 hover:text-gray-700 focus:outline-none"
            onClick={onClose}>
            <Icons.Close />
          </button>
        </div>
      </div>
    </>
  );
};

export default DatabaseInfoModal;

const DataRoleList: React.FC<{ dbInfo: DataRoleDBInfo[] }> = ({ dbInfo }) => {
  // Sort dataRoles by weight
  const sortedDBInfo = dbInfo.sort((a, b) => a.weight - b.weight);

  return (
    <div className="flex flex-col gap-4">
      {sortedDBInfo.map((dataRole) => (
        <SourceList key={dataRole.label} sources={dataRole.sources} label={dataRole.label} />
      ))}
    </div>
  );
};

const SourceList: React.FC<{ sources: DataBaseInfoSource[]; label?: string }> = ({
  sources,
  label,
}) => {
  const sortedSources = useMemo(() => sources.sort((a, b) => a.weight - b.weight), [sources]);

  return (
    <div className="flex flex-col items-stretch self-stretch gap-4 mt-10">
      {label && <h4 className="text-md font-medium">{label}</h4>}
      {sortedSources.map((source, index: number) => (
        <SourceItem key={index} source={source} />
      ))}
    </div>
  );
};

const SourceItem: React.FC<{ source: DataBaseInfoSource }> = ({ source }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [subContainerHeight, setSubContainerHeight] = useState<number>(0);
  const subContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      setSubContainerHeight(subContainerRef.current?.clientHeight ?? 0);
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (subContainerRef.current) {
      resizeObserver.observe(subContainerRef.current);
    }

    return () => {
      if (subContainerRef.current) {
        resizeObserver.unobserve(subContainerRef.current);
      }
    };
  }, [subContainerRef]);

  const sortedSources = useMemo(
    () => source.sub_sources?.sort((a, b) => a.weight - b.weight) ?? null,
    [source.sub_sources],
  );

  const handleSourceItemStatus: MouseEventHandler<HTMLDivElement> = () => {
    setIsOpen(!isOpen);
  };

  const dateString = useMemo(() => {
    const today = new Date();
    const startDate = new Date(
      source.start_date[0],
      source.start_date[1] - 1,
      source.start_date[2],
    );
    const endDate = new Date(source.end_date[0], source.end_date[1] - 1, source.end_date[2]);

    const clampedStartDate = startDate > today ? today : startDate;
    const clampedEndDate = endDate > today ? today : endDate;

    return `${clampedStartDate.toLocaleDateString()} - ${clampedEndDate.toLocaleDateString()}`;
  }, [source.start_date, source.end_date]);

  return (
    <div className={`flex flex-col`}>
      <div
        onClick={sortedSources ? handleSourceItemStatus : undefined}
        className={`${isOpen ? 'bg-qura-neutral-ghost' : ''} flex items-center justify-between hover:bg-backgroundColor-gray cursor-pointer py-1 px-2 mr-2 rounded`}>
        <div className="flex gap-2 items-center">
          <p className="text-qura-neutral-jet text-sm leading-[16px]">{source.label}</p>
          <p className="text-qura-neutral-balanced text-xs font-thin leading-[14px]">
            {dateString}
          </p>
        </div>
        <div className="flex gap-1 items-center justify-between">
          <Icons.Document className="" />
          <p className="text-qura-neutral-jet text-xs w-20">
            {new Intl.NumberFormat('en-US', {
              style: 'decimal',
              useGrouping: true,
              minimumFractionDigits: 0,
            })
              .format(source.document_count)
              .replace(/,/g, ' ')}
          </p>
          <Icons.Arrow
            className="transition-transform mx-2"
            style={{
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              opacity: sortedSources ? '1' : '0',
            }}
          />
        </div>
      </div>
      <div
        className="relative overflow-hidden transition-height"
        style={{ height: isOpen ? subContainerHeight : 0 }}>
        <div
          className={'absolute left-0 right-0 flex flex-col ml-4 gap-2 pt-3'}
          ref={subContainerRef}>
          {sortedSources?.map((item) => <SourceItem key={item.label} source={item} />)}
        </div>
      </div>
    </div>
  );
};
