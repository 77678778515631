import 'react-pdf/dist/Page/TextLayer.css';

import { Document, Page } from 'react-pdf';
import { useVirtualizer, VirtualItem, Virtualizer } from '@tanstack/react-virtual';
import { useEffect, useMemo, useRef } from 'react';
import { PDFPageLoading } from './LoadingPage';
import { Overlays } from './Overlay';
import { alertError } from '@/utils/alertError';
import { useAuthToken } from '@/services/firebase/functions/getCurrentUser';
import { getDocumentUrl } from '@/services/api/utils';
import { FileProps, PageProps } from '@/services/api/apiValidation';
import { DocumentProperties } from '@/services/api/apiValidation';

export type FilePosition = {
  pageNumber: number;
  id: number;
  offset: number;
};

const PageItem = ({
  virtualItem,
  pageProps,
  zoom,
  pageIndex,
  pageModifications,
  virtualizer,
}: {
  virtualItem: VirtualItem<Element>;
  pageProps: DocumentProperties['pageProperties'];
  zoom: number;
  pageIndex: number;
  pageModifications?: PageProps;
  virtualizer: Virtualizer<HTMLDivElement, Element>;
}) => {
  const pageHeight = pageProps[virtualItem.index]?.height;
  const pageWidth = pageProps[virtualItem.index]?.width;
  if (!pageHeight || !pageWidth) return null;

  return (
    <div
      key={virtualItem.key}
      ref={virtualizer.measureElement}
      data-index={virtualItem.index}
      className={`${
        pageIndex % 2 ? 'ListItemOdd' : 'ListItemEven'
      } top-0 left-0 right-0 mr-auto ml-auto absolute`}
      style={{
        width: pageWidth * zoom,
        height: pageHeight * zoom,
        transform: `translateY(${virtualItem.start}px)`,
      }}>
      {pageModifications && <Overlays pageModifications={pageModifications} zoom={zoom} />}
      <Page
        pageIndex={virtualItem.index}
        width={pageWidth}
        scale={zoom}
        loading={<PDFPageLoading width={pageWidth} height={pageHeight} />}
        error={<PDFPageLoading width={pageWidth} height={pageHeight} />}
        renderAnnotationLayer={false}
      />
    </div>
  );
};

const VirtualizerList = ({
  fileModifications,
  positionInFile,
  documentProps,
  zoom,
}: {
  positionInFile?: FilePosition;
  fileModifications?: FileProps;
  documentProps: DocumentProperties;
  zoom: number;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const pageProps = documentProps?.pageProperties;
  const virtualizer = useVirtualizer({
    count: documentProps?.pageCount,
    getScrollElement: () => ref.current,
    estimateSize: (i: number) => (pageProps[i]?.height || 0) * zoom,
    overscan: 10,
    gap: 10,
  });

  const items = virtualizer.getVirtualItems();

  // Make scroll smooth when scrolling many pages
  useEffect(() => {
    if (!positionInFile || !virtualizer.range) return;
    const middleIndex = Math.floor((virtualizer.range.startIndex + virtualizer.range.endIndex) / 2);
    positionInFile.id = middleIndex;
  }, [positionInFile, virtualizer.range]);

  useEffect(() => {
    if (!positionInFile) return;
    const offset = virtualizer.getOffsetForIndex(positionInFile.pageNumber, 'start');
    if (offset) virtualizer.scrollToOffset(offset[0] - 10 + positionInFile.offset);
  }, [positionInFile, virtualizer]);

  return (
    <div
      ref={ref}
      className="h-full w-full overflow-y-auto overflow-x-auto flex justify-center [contain:strict] bg-qura-neutral-ghost rounded-b-xl">
      <div style={{ height: virtualizer.getTotalSize() }} className={`relative w-full`}>
        {items.map((virtualItem) => (
          <PageItem
            key={virtualItem.key}
            virtualItem={virtualItem}
            pageIndex={virtualItem.index}
            pageProps={pageProps}
            zoom={zoom}
            virtualizer={virtualizer}
            pageModifications={fileModifications?.pageOverrides?.find(
              (pageOverride) => pageOverride.pageNumber === virtualItem.index,
            )}
          />
        ))}
      </div>
    </div>
  );
};

const TextViewer = ({
  documentId,
  documentUnitId,
  fileModifications,
  documentProperties,
  zoom,
  onLoadSuccess,
}: {
  documentId: string;
  documentUnitId: string;
  fileModifications?: FileProps;
  documentProperties: DocumentProperties;
  zoom: number;
  onLoadSuccess: () => void;
}) => {
  const { data: token } = useAuthToken();

  const file = useMemo(
    () => ({ url: getDocumentUrl(documentId, documentUnitId) }),
    [documentId, documentUnitId],
  );

  const options = useMemo(() => ({ httpHeaders: { Authorization: `Bearer ${token}` } }), [token]);

  const onError = (err: unknown) => {
    alertError(
      err,
      { component: 'Virtualizer' },
      { documentId, documentUnitId, fileModifications },
    );
  };

  return (
    <>
      <Document
        file={file}
        options={options}
        onLoadSuccess={onLoadSuccess}
        onError={onError}
        onLoadError={onError}
        loading={
          <PDFPageLoading
            width={documentProperties?.pageProperties[0].width ?? 595}
            height={documentProperties?.pageProperties[0].height ?? 842}
          />
        }>
        <VirtualizerList
          fileModifications={fileModifications}
          documentProps={documentProperties}
          zoom={zoom}
        />
      </Document>
    </>
  );
};

export default TextViewer;
