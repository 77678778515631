import { Icons } from '@/assets';

export const PDFPageLoading = ({ width, height }: { width: number; height: number }) => (
    <div
      style={{
        width: width,
        height: height,
      }}
      className="flex justify-center items-center">
      <div>
        <Icons.LogoText className=" h-4" />
      </div>
    </div>
  );