import posthog from 'posthog-js';
import { addDoc, collection } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import * as Sentry from '@sentry/browser';
import { Primitive } from 'zod';
import { getLogMetadata } from './getLogData';
import devlog from '@/utils/devlog';
import { db } from '@/services/firebase/firebaseConfig';
import { POSTHOG_EVENT } from '@/services/posthog/events';

export async function alertError(
  error: unknown,
  tags?: { [key: string]: Primitive },
  details?: any,
) {
  const stack = new Error().stack;
  const caller = stack ? stack.split('\n')[2].trim() : 'Unknown caller';
  const searchId = tags?.searchId;
  let errorData = undefined;
  if (typeof error === 'string') {
    errorData = error;
  } else if (error instanceof Error) {
    errorData = {
      message: error.message,
      stack: error.stack,
    };
  } else {
    errorData = JSON.stringify(error, null, 2);
  }
  const authUser = getAuth().currentUser;

  devlog('ALERTING ERROR', {
    error,
    tags,
    details,
    caller,
    searchId,
    stack,
  });

  Sentry.captureException(error, {
    tags: {
      ...tags,
      callingFunction: caller,
      userId: authUser?.uid ?? null,
      searchId: searchId ?? null,
    },
    level: 'error',
    extra: {
      details: details ?? null,
      errorData,
    },
  });

  posthog.capture(POSTHOG_EVENT.APP_ERROR, {
    error: errorData,
    details,
    tags,
    callingFunction: caller,
    userId: authUser?.uid ?? null,
    searchId: searchId ?? null,
  });

  const fullData = {
    stack: errorData instanceof Error ? errorData.stack : null,
    error: errorData ?? null,
    details: details ?? null,
    tags: tags ?? null,
    callingFunction: caller ?? null,
    logMetadata: getLogMetadata() ?? null,
  };
  try {
    await addDoc(collection(db, 'errorLog'), {
      message:
        error && typeof error === 'object' && 'message' in error ? error.message : 'Unknown error',
      timestamp: new Date(),
      userId: authUser?.uid ?? null,
      searchId: searchId ?? null,
      link: window.location.href ?? null,
      platform: window.location.origin.replace('https://', '') ?? null,
      data: fullData,
    });
  } catch (error) {
    console.error('Failed to upload error log to Firebase', error);
  }
}
