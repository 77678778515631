import { useParams } from 'react-router-dom';
import { useSearchStore } from '@/features/Search/state/searchStore';
import { useAvailableFilterTags } from '@/services/api/actions/getAvailableFilterTags';
import { developerMode } from '@/services/posthog/featureFlags';
import { useDeveloperStore } from '@/features/Search/state/developerStore';
import { useEffect } from 'react';

export const DocumentDeveloperView = (data: any) => (
  <>
    {false && (
      <div className="p-2 mt-4 bg-gray-100 rounded">
        <h3 className="text-sm font-semibold mb-2">Developer Mode: Document</h3>
        <pre className="text-xs overflow-auto">{JSON.stringify(data, null, 2)}</pre>
      </div>
    )}
  </>
);

export const SearchProgressDeveloperView = (progress: any) => (
  <>
    {false && (
      <div className="p-2 mt-4 bg-gray-100 rounded overflow-auto max-h-[300px]">
        <h3 className="text-sm font-semibold mb-2">Developer Mode: Search Progress</h3>
        <pre className="text-xs overflow-auto">{JSON.stringify(progress, null, 2)}</pre>
      </div>
    )}
  </>
);

export const GeneralDeveloperView = () => {
  const search = useSearchStore();
  const filters = useAvailableFilterTags(search.currentParams.dataRole ?? '');
  const params = useParams();
  const data = {
    params,
    search: search.currentParams,
    error: search.error,
    filters: filters.data,
  };

  return (
    <>
      {false && (
        <div className="p-2 bg-gray-100 rounded max-h-[400px] overflow-auto m-3">
          <h3 className="text-sm font-semibold mb-2">Developer Mode: General</h3>
          <pre className="text-xs">{JSON.stringify(data, null, 2)}</pre>
        </div>
      )}
    </>
  );
};

interface DeveloperTextFieldProps {
  position?: { bottom?: number; left?: number; right?: number; top?: number };
  size?: { width?: number; height?: number };
  textareaKey: string;
  title: string;
  defaultValue?: string;
}

export const DeveloperTextField = ({
  position = { bottom: 4, left: 4 },
  size = { width: 64, height: 32 },
  textareaKey,
  title,
  defaultValue,
}: DeveloperTextFieldProps) => {
  const textEntry = useDeveloperStore((state) => state.textEntries[textareaKey]);
  const setTextEntry = useDeveloperStore((state) => (value: string) => {
    console.log('setTextEntry', textareaKey, value);
    state.textEntries[textareaKey] = value;
  });

  // Set the default value to the state when the component mounts
  useEffect(() => {
    if (defaultValue && !textEntry) {
      setTextEntry(defaultValue);
    }
  }, [defaultValue]);

  return (
    <>
      {developerMode && (
        <div className="fixed z-50" style={{ ...position }}>
          <div className="relative">
            <h4 className="absolute -top-3 left-2 px-1 text-xs font-semibold bg-white z-10">
              {title}
            </h4>
            <textarea
              key={textareaKey}
              className="p-2 pt-4 text-sm bg-white border border-gray-300 rounded shadow-lg resize-none"
              style={{ width: `${size.width}px`, height: `${size.height}px` }}
              placeholder={`${title}...`}
              defaultValue={textEntry || ''}
              onChange={(e) => setTextEntry(e.target.value)}
            />
          </div>
        </div>
      )}
    </>
  );
};
