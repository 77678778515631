import { useEffect, useMemo } from 'react';
import { setActiveDataRole } from './features/Search/state/actions/searchParams';
import { useUserDataStore } from './services/firebase/state/userDataStore';
import { useSearchStore } from './features/Search/state/searchStore';

export const SetDefaultDataRole = () => {
  const { data } = useUserDataStore();

  const dataRoles = useMemo(() => {
    return data ? [...data.storedUser.dataRoles].sort((a, b) => a.weight - b.weight) : null;
  }, [data]);

  useEffect(() => {
    if (!dataRoles) return;
    if (useSearchStore.getState().currentParams.dataRole) return;

    const params = new URLSearchParams(window.location.search);
    const role = params.get('role');

    if (role) {
      const roleFromId = dataRoles.find((r) => r.roleName === role);
      if (roleFromId) {
        setActiveDataRole(roleFromId.roleName);
        return;
      }

      const roleFromLabel = dataRoles.find((r) => r.label.toLowerCase() === role.toLowerCase());
      if (roleFromLabel) {
        setActiveDataRole(roleFromLabel.roleName);
        return;
      }
    }

    setActiveDataRole(dataRoles[0].roleName);
  }, [dataRoles]);

  return null;
};
