import { ReactNode, useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { SideColumn } from './SideColumn';
import { TopBar } from './TopBar';
import Feedback from '../Feedback/Feedback';
import { HelpButton } from '../HelpButton/HelpButton';
import SearchHistoryModal from '@/features/Search/components/SearchHistoryModal/SearchHistoryModal.tsx';
import { DeveloperTextField } from '@/utils/developerMode';

const TOP_BAR_GAP = 24;



const DEFAULT_SYSTEM_PROMPT = `Analysera frågan och det valda dokumentet noggrant. Förklara mycket kortfattat och kärnfullt varför just det givna dokumentet är relevant för frågan. Om dokumentet inte är relevant, sammanfatta vad dokumentet innehåller. Vilka specifika detaljer i dokumentet gör det relevant jämfört med de andra dokumenten? Tänk noga och skriv sedan en kortfattad men kärnfull förklaring med endast nyckelord. Skriv max 10 ord.`;
const DEFAULT_USER_PROMPT = `Fråga: '{query}'
Valt dokument:
'''
{document}
'''
Övriga dokument i samlingen:
'''
{other_documents}
'''
Skriv en kortfattad men kärnfull förklaring på max 10 ord.`;

const Layout = ({
  query,
  onSearch,
  selectDocument,
  children,
  leftContent,
  rightContent,
  showBackButtonLink,
  contentClassName,
  originalSearchId,
  greyedOut,
}: {
  onSearch: () => void;
  selectDocument: (documentId: string) => void;
  query?: string;
  children: ReactNode;
  leftContent?: () => ReactNode;
  rightContent?: () => ReactNode;
  showBackButtonLink?: string;
  originalSearchId?: string;
  contentClassName?: string;
  greyedOut?: boolean;
}) => {
  const leftRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLDivElement>(null);
  const topBarRef = useRef<HTMLHeadElement>(null);

  const [leftWidth, setLeftWidth] = useState<number>(0);
  const [rightWidth, setRightWidth] = useState<number>(0);
  const [topBarHeight, setTopBarHeight] = useState<number>(0);
  const [isSearchHistoryModalOpened, setSearchHistoryModalOpened] = useState(false);

  useEffect(() => {
    const updateDimensions = () => {
      if (topBarRef.current) setTopBarHeight(topBarRef.current.clientHeight + TOP_BAR_GAP);
      if (leftRef.current) setLeftWidth(leftRef.current.clientWidth);
      if (rightRef.current) setRightWidth(rightRef.current.clientWidth);
    };

    const resizeObserver = new ResizeObserver(updateDimensions);
    window.addEventListener('resize', updateDimensions);

    if (topBarRef.current) resizeObserver.observe(topBarRef.current);
    if (leftRef.current) resizeObserver.observe(leftRef.current);
    if (rightRef.current) resizeObserver.observe(rightRef.current);

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  const handleSearchHistoryModalStatus = () => {
    setSearchHistoryModalOpened(!isSearchHistoryModalOpened);
  };

  return (
    <main id="main" className="flex flex-col items-center" style={{ paddingTop: topBarHeight }}>
      <TopBar
        initialQuery={query}
        onSearch={onSearch}
        ref={topBarRef}
        showBackButtonLink={showBackButtonLink}
        originalSearchId={originalSearchId}
        selectDocument={selectDocument}
        onOpenSearchHistoryModal={handleSearchHistoryModalStatus}
        greyedOut={greyedOut}
      />
      <div
        className="flex w-full"
        style={{
          height: `calc(100vh - ${topBarHeight}px - 1rem)`,
        }}>
        <div className="flex flex-1" ref={leftRef} />
        <div className={`${contentClassName}`}>{children}</div>
        <div className="flex flex-1" ref={rightRef} />
      </div>

      <div
        className="fixed left-0 bottom-4 "
        style={{
          top: topBarHeight,
          width: leftWidth - 8,
        }}>
        <SideColumn side="left">{leftContent && leftContent()}</SideColumn>
      </div>

      <div
        className="fixed right-0 bottom-4"
        style={{
          top: topBarHeight,
          width: rightWidth - 8,
        }}>
        <SideColumn side="right">{rightContent && rightContent()}</SideColumn>
      </div>
      {isSearchHistoryModalOpened && (
        <SearchHistoryModal onClose={handleSearchHistoryModalStatus} onSearch={onSearch} />
      )}
      <Feedback />
      <HelpButton hideDetails />
      <ToastContainer className="pointer-events-auto" />
      <DeveloperTextField
        position={{ bottom: 320, left: 0 }}
        size={{ width: 400, height: 300 }}
        textareaKey="systemPrompt"
        title="System prompt"
        defaultValue={DEFAULT_SYSTEM_PROMPT}
      />
      <DeveloperTextField
        position={{ bottom: 0, left: 0 }}
        size={{ width: 400, height: 300 }}
        textareaKey="userPrompt"
        title="User prompt"
        defaultValue={DEFAULT_USER_PROMPT}
      />
    </main>
  );
};

export default Layout;
