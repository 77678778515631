import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';
import { doc, getDoc } from 'firebase/firestore';
import { alertError } from '@/utils/alertError';
import { db } from '@/services/firebase/firebaseConfig';

type _DataBaseInfoSource = {
  document_count: number;
  weight: number;
  label: string;
  start_date: number[];
  end_date: number[];
  sub_sources: _DataBaseInfoSource[] | null;
};

const dataBaseInfoSourceSchema: z.ZodSchema<_DataBaseInfoSource> = z.object({
  document_count: z.number(),
  weight: z.number(),
  label: z.string(),
  start_date: z.array(z.number()),
  end_date: z.array(z.number()),
  sub_sources: z.lazy(() => dataBaseInfoSourceSchema.array()).nullable(),
});

const dataRoleDBInfoSchema = z.object({
  label: z.string(),
  weight: z.number(),
  sources: z.array(dataBaseInfoSourceSchema),
});

const databaseInfoResponseSchema = z.object({
  data_roles: z.array(dataRoleDBInfoSchema),
});

export type DatabaseInfoResponse = z.infer<typeof databaseInfoResponseSchema>;
export type DataRoleDBInfo = z.infer<typeof dataRoleDBInfoSchema>;
export type DataBaseInfoSource = z.infer<typeof dataBaseInfoSourceSchema>;

async function getDatabaseInfo(dataRoles: string[]): Promise<DatabaseInfoResponse> {
  try {
    const cacheDocRefs = dataRoles.map((dataRole) =>
      doc(db, 'cache', 'dbInfo', 'dataRoles', dataRole),
    );
    const cacheDocs = await Promise.all(cacheDocRefs.map((docRef) => getDoc(docRef)));
    const cacheData = cacheDocs.map((doc) => doc.data());
    console.log('cacheData', cacheData);

    const databaseInfo = databaseInfoResponseSchema.parse({
      data_roles: cacheData,
    });

    return databaseInfo;
  } catch (error) {
    await alertError(error, { function: 'getDatabaseInfo' });
    throw error;
  }
}

export const useDatabaseInfo = (dataRoles: string[]) => {
  return useQuery({
    queryKey: ['database_info', dataRoles],
    queryFn: () => getDatabaseInfo(dataRoles),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
