import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';
import { useParams } from 'react-router-dom';
import { FileProps } from '@/services/api/apiValidation';
import { Icons } from '@/assets';
import { POSTHOG_EVENT } from '@/services/posthog/events';

type DownloadButtonProps = {
  documentId: string;
  documentUnitId: string;
  fileModifications?: FileProps;
  identifier: string;
};
const DownloadButton = (props: DownloadButtonProps) => {
  const { documentId, documentUnitId, fileModifications, identifier } = props;
  const { searchId = '' } = useParams();
  const posthog = usePostHog();
  const { t } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState<'print' | 'download'>('download');

  const showDropdownClick = (trigger: 'print' | 'download') => {

    console.log('showDropdownClick', trigger, selectedOption);
    setShowDropdown(!showDropdown || trigger !== selectedOption);
    setSelectedOption(trigger);
  };

  const onDownloadDocumentWithHighlighting = () => {
    posthog.capture(POSTHOG_EVENT.DOWNLOAD_PDF_WITH_HIGHLIGHTING, {
      documentId,
      documentUnitId,
      identifier,
      searchId,
      fileModifications,
    });
    console.log('download document with highlighting', selectedOption);
    setShowDropdown(false);
  };

  const onDownloadDocumentWithoutHighlighting = () => {
    posthog.capture(POSTHOG_EVENT.DOWNLOAD_PDF_WITHOUT_HIGHLIGHTING, {
      documentId,
      documentUnitId,
      identifier,
      searchId,
      fileModifications,
    });
    console.log('download document without highlighting', selectedOption);

    setShowDropdown(false);
  };

  const onDownloadHighlightedPages = () => {
    posthog.capture(POSTHOG_EVENT.DOWNLOAD_PDF_ONLY_HIGHLIGHTS, {
      documentId,
      documentUnitId,
      identifier,
      searchId,
      fileModifications,
    });
    console.log('download highlighted pages', selectedOption);
    setShowDropdown(false);
  };

  return (
    <>
      <div className="relative flex items-center h-7 rounded-md border border-qura-neutral-silver bg-qura-neutral-ghost overflow-visible">
        <button
          className="w-7 h-full text-qura-neutral-jet flex items-center justify-center border-r border-qura-neutral-silver hover:bg-white focus:outline-none transition-colors rounded-l-md"
          aria-label="Print"
          onClick={() => showDropdownClick('print')}>
          <Icons.Print className="size-3.5" />
        </button>
        <button
          className="w-7 h-full text-qura-neutral-jet flex items-center justify-center hover:bg-white focus:outline-none transition-colors rounded-r-md"
          aria-label="Download"
          onClick={() => showDropdownClick('download')}>
          <Icons.Download className="size-3.5" />
        </button>
      </div>
      {showDropdown && (
        <div
          ref={dropdownRef}
          className="absolute bg-white rounded-lg flex flex-col shadow-qura px-2 py-2 z-10 left-1/2 transform -translate-x-1/2 top-full mt-1 text-xs w-max">
          <button
            className="rounded-[6px] p-2 cursor-pointer hover:bg-qura-neutral-ghost text-left whitespace-nowrap"
            onClick={onDownloadDocumentWithHighlighting}>
            <p>
              {selectedOption === 'download'
                ? t('searchDocumentPage.downloadDocumentWithHighlights')
                : t('searchDocumentPage.printDocumentWithHighlights')}
            </p>
          </button>
          <button
            className="rounded-[6px] p-2 cursor-pointer hover:bg-qura-neutral-ghost text-left whitespace-nowrap"
            onClick={onDownloadDocumentWithoutHighlighting}>
            <p>
              {selectedOption === 'download'
                ? t('searchDocumentPage.downloadDocumentWithoutHighlights')
                : t('searchDocumentPage.printDocumentWithoutHighlights')}
            </p>
          </button>
          <button
            className="rounded-[6px] p-2 cursor-pointer hover:bg-qura-neutral-ghost text-left whitespace-nowrap"
            onClick={onDownloadHighlightedPages}>
            <p>
              {selectedOption === 'download'
                ? t('searchDocumentPage.downloadHighlightedPages')
                : t('searchDocumentPage.printHighlightedPages')}
            </p>
          </button>
        </div>
      )}
    </>
  );
};

export default DownloadButton;

{
  /* <div
          ref={dropdownRef}
        className={`absolute bg-white rounded-lg flex flex-col justify-start shadow-qura px-2 py-2 z-10 top-10 text-xs ${showDropdown ? 'visible' : 'hidden'}`}>
        <button
          className="rounded-[6px] p-2 cursor-pointer hover:bg-qura-neutral-ghost text-left"
          onClick={onDownloadWithHighlighting}>
          <p>{t('searchDocumentPage.downloadWithHighlights')}</p>
        </button>
        <button
          className="rounded-[6px] p-2 cursor-pointer hover:bg-qura-neutral-ghost text-left"
          onClick={onDownloadWithoutHighlighting}>
          <p>{t('searchDocumentPage.downloadWithoutHighlights')}</p>
        </button>
        <button
          className="rounded-[6px] p-2 cursor-pointer hover:bg-qura-neutral-ghost text-left"
          onClick={onDownloadOnlyHighlights}>
          <p>{t('searchDocumentPage.downloadOnlyHighlights')}</p>
        </button>
      </div>
      <button
        ref={downloadButtonRef}
        className={`text-nowrap border border-qura-neutral-silver text-qura-neutral-jet hover:bg-white bg-qura-neutral-ghost text-xs px-2 h-7 rounded ${downloading ? 'cursor-not-allowed animate-pulse' : 'cursor-pointer'}`}
        onClick={downloadClick}>
        {t('searchDocumentPage.downloadPdf')}
      </button> */
}
// </div> */}

//   const onDownloadWithHighlighting = () => {
//     posthog.capture(POSTHOG_EVENT.DOWNLOAD_PDF_WITH_HIGHLIGHTING, {
//         identifier,
//       searchId,
//       fileModifications,
//     });
//     setDownloading(true);
//     downloadPDF(documentUrl, fileProps)
//       .then(() => {
//         setDownloading(false);
//       })
//       .catch((error) => {
//         alertError(error);
//         setDownloading(false);
//         toast.error(t('searchDocumentPage.downloadError'));
//       });
//     setShowDropdown(false);
//   };

//   const onDownloadWithoutHighlighting = () => {
//     setDownloading(true);
//     posthog.capture(POSTHOG_EVENT.DOWNLOAD_PDF_WITHOUT_HIGHLIGHTING, {
//       identifier,
//       searchId,
//       fileModifications,
//     });
//     downloadPDF(documentUrl)
//       .then(() => {
//         setDownloading(false);
//       })
//       .catch((error) => {
//         alertError(error);
//         setDownloading(false);
//         toast.error(t('searchDocumentPage.downloadError'));
//       });
//     setShowDropdown(false);
//   };

//   const downloadPDF = async (url: string, fileProps?: FileProps) => {
//     const authToken = await getAuthToken();

//     const response = await axios({
//       url: url,
//       method: 'POST',
//       headers: {
//         Authorization: `Bearer ${authToken}`,
//       },
//       responseType: 'blob',
//       data: fileProps ? convertFilePropsToSnakeCase(fileProps) : undefined,
//     });

//     const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
//     const link = document.createElement('a');
//     link.href = fileUrl;
//     link.setAttribute('download', `${title.replace(/[^a-zåäö0-9]+/gi, '-')}.pdf`);
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   };

//   const downloadClick = () => {
//     if (downloading) return;
//     if (fileProps) {
//       setShowDropdown(!showDropdown);
//     } else {
//       setShowDropdown(false);
//       onDownloadWithoutHighlighting();
//     }
//   };

//   const hideDropdown = (event: MouseEvent) => {
//     if (
//       dropdownRef.current &&
//       !dropdownRef.current.contains(event.target as Node) &&
//       downloadButtonRef.current &&
//       !downloadButtonRef.current.contains(event.target as Node)
//     ) {
//       setShowDropdown(false);
//     }
//   };

//   const onDownloadOnlyHighlights = () => {
//     setDownloading(true);
//     posthog.capture(POSTHOG_EVENT.DOWNLOAD_PDF_ONLY_HIGHLIGHTS, {
//       title,
//       searchId,
//       url: documentUrl,
//       fileProps,
//     });

//     const customFileProps: FileProps = {
//       pageSelection: fileProps?.pageOverrides?.map((page) => page.pageNumber).concat([0]) ?? [],
//       pageOverrides: fileProps?.pageOverrides ?? null,
//     };

//     console.log(customFileProps);

//     downloadPDF(documentUrl, customFileProps)
//       .then(() => {
//         setDownloading(false);
//       })
//       .catch((error) => {
//         alertError(error);
//         setDownloading(false);
//         toast.error(t('searchDocumentPage.downloadError'));
//       });

//     setShowDropdown(false);
//   };
