import { useState } from 'react';

export const TextHighlight = ({
  onClick,
  onHoverStateChange,
  color = 'rgba(217, 255, 217, 1)',
  rect,
}: {
  onClick?: () => void;
  onHoverStateChange?: (isHovered: boolean) => void;
  color?: string;
  rect: {
    x0: number;
    x1: number;
    y0: number;
    y1: number;
  };
}) => {
  return (
    <div
      onClick={onClick}
      onMouseEnter={() => onHoverStateChange?.(true)}
      onMouseLeave={() => onHoverStateChange?.(false)}
      style={{
        cursor: 'pointer',
        position: 'absolute',
        zIndex: 100,
        mixBlendMode: 'multiply',
        background: color,
        top: rect.y0,
        left: rect.x0,
        width: rect.x1 - rect.x0,
        height: rect.y1 - rect.y0,
      }}
    />
  );
};

export const KeywordHighlight = ({
  onClick,
  onHoverStateChange,
  color = 'rgba(250, 255, 250, 0.8)',
  borderColor = 'rgba(100, 255, 100, 0.8)',
  rect,
  borderWidth = 1,
}: {
  onClick?: () => void;
  onHoverStateChange?: (isHovered: boolean) => void;
  color?: string;
  borderColor?: string;
  rect: {
    x0: number;
    x1: number;
    y0: number;
    y1: number;
  };
  borderWidth?: number;
}) => {
  return (
    <div
      onClick={onClick}
      onMouseEnter={() => onHoverStateChange?.(true)}
      onMouseLeave={() => onHoverStateChange?.(false)}
      style={{ position: 'absolute', cursor: 'pointer' }}>
      <div
        style={{
          position: 'absolute',
          zIndex: 100,
          mixBlendMode: 'multiply',
          background: borderColor,
          top: rect.y0,
          left: rect.x0,
          width: rect.x1 - rect.x0,
          height: rect.y1 - rect.y0,
        }}
      />
      <div
        style={{
          position: 'absolute',
          zIndex: 101,
          mixBlendMode: 'soft-light',
          background: color,
          top: rect.y0 + borderWidth,
          left: rect.x0 + borderWidth,
          width: rect.x1 - rect.x0 - 2 * borderWidth,
          height: rect.y1 - rect.y0 - 2 * borderWidth,
        }}
      />
    </div>
  );
};

export const LinkHighlight = ({
  onClick,
  onHoverStateChange,
  color = 'rgba(0, 0, 255, 1)',
  hoverColor = 'rgba(240, 240, 200, 1)',
  rect,
}: {
  onClick?: () => void;
  onHoverStateChange?: (isHovered: boolean) => void;
  color?: string;
  hoverColor?: string;
  rect: {
    x0: number;
    x1: number;
    y0: number;
    y1: number;
  };
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    onHoverStateChange?.(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    onHoverStateChange?.(false);
  };

  return (
    <div
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        cursor: 'pointer',
        position: 'absolute',
        zIndex: 100,
        mixBlendMode: isHovered ? 'multiply' : 'screen',
        background: isHovered ? hoverColor : color,
        top: rect.y0,
        left: rect.x0,
        width: rect.x1 - rect.x0,
        height: rect.y1 - rect.y0,
      }}
    />
  );
};
