import { Icons } from '@/assets';

type ZoomInputProps = { scale: number; setScale: (value: number) => void };
const ZoomInput = ({ scale, setScale }: ZoomInputProps) => {
  const incrementScale = () => setScale(Math.min(scale + 15, 300));
  const decrementScale = () => setScale(Math.max(scale - 15, 50));

  return (
    <div className="flex items-center h-7 rounded-md border border-qura-neutral-silver bg-qura-neutral-ghost overflow-hidden">
      <div className="flex items-center justify-center w-7 h-full text-qura-neutral-jet">
        <Icons.Search className="size-3.5" />
      </div>
      <button
        onClick={decrementScale}
        className="w-7 h-full text-qura-neutral-jet flex items-center justify-center border-l  border-qura-neutral-silver hover:bg-white focus:outline-none transition-colors"
        aria-label="Decrease zoom">
        <Icons.Minus className="size-3.5" />
      </button>
      <button
        onClick={incrementScale}
        className="w-7 h-full text-qura-neutral-jet flex items-center justify-center border-l border-qura-neutral-silver hover:bg-white focus:outline-none transition-colors"
        aria-label="Increase zoom">
        <Icons.Plus className="size-3.5" />
      </button>
    </div>
  );
};

export default ZoomInput;
