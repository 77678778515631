import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { REST_API_URL } from '../constants';
import { standardAxiosOptions } from '../utils';
import { documentPropertiesSchema } from '../apiValidation';
import { getAuthToken } from '@/services/firebase/functions/getCurrentUser';

async function getDocumentProperties(documentId: string, documentUnitId?: string) {
  console.log('documentUnitId', documentUnitId);
  const token = await getAuthToken();
  const response = await axios.get(
    `${REST_API_URL}/database/document/${documentId}/properties`,
    standardAxiosOptions(token),
  );
  return documentPropertiesSchema.parse(response.data);
}

export const useDocumentProperties = (documentId: string, documentUnitId?: string) => {
  return useQuery({
    queryKey: ['filter_dates', documentId, documentUnitId],
    queryFn: () => getDocumentProperties(documentId, documentUnitId),
    retry: 1,
  });
};
