import { keepPreviousData, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { MAX_COMPLETIONS_PER_SEARCH, REST_API_URL } from '../constants';
import { standardAxiosOptions } from '../utils';
import { getAuthToken } from '@/services/firebase/functions/getCurrentUser';

const getDocumentSuggestions = async (prefix: string, dataRole: string) => {
  if (prefix.trim().length === 0) {
    return { completions: [] };
  }

  const token = await getAuthToken();
  const response = await axios.post<{
    completions: { legal_id: string; title: string; document_id: string }[];
  }>(
    `${REST_API_URL}/database/completion/legal`,
    {
      prefix,
      limit: MAX_COMPLETIONS_PER_SEARCH,
      data_role: dataRole,
    },
    {
      responseType: 'json',
      ...standardAxiosOptions(token),
    },
  );

  return response.data;
};

export const useDocumentSuggestions = (
  prefix: string,
  dataRole: string | null,
  enabled: boolean,
) => {
  return useQuery({
    queryKey: ['document_suggestions', prefix, dataRole],
    queryFn: () => getDocumentSuggestions(prefix, dataRole ?? ''),
    placeholderData: keepPreviousData,
    enabled: enabled && dataRole !== null,
  });
};
