import { useEffect, useRef, useState } from 'react';

import isEqual from 'lodash.isequal';
import { PDFReaderTopBar } from './PDFReaderTopBar';
import { Icons } from '@/assets';
import { DocumentProperties, FileProps } from '@/services/api/apiValidation';
import TextViewer from '../TextViewer/TextViewer';

interface PDFReaderProps {
  documentId: string;
  documentUnitId: string;
  documentProperties?: DocumentProperties;
  legalId?: string;
  fileProps?: FileProps;
}

const PDFReader = (props: PDFReaderProps) => {
  const { documentId, documentUnitId, documentProperties, fileProps } = props;
  const [scale, setScale] = useState(100);
  const [virtualizerLoaded, setVirtualizerLoaded] = useState(false);
  const [virtualizerKey, setVirtualizerKey] = useState(0);
  const filePropsRef = useRef(fileProps);

  useEffect(() => {
    if (isEqual(fileProps, filePropsRef.current)) return;
    filePropsRef.current = fileProps;
    setVirtualizerKey((prevKey) => prevKey + 1);
  }, [fileProps]);

  return (
    <>
      <div className="grid grid-cols-1 grid-rows-[3.5rem_1fr] relative rounded-xl shadow-qura h-full w-full bg-white overflow-clip">
        <PDFReaderTopBar
          scale={scale}
          setScale={setScale}
          documentProperties={documentProperties}
          documentId={documentId}
          documentUnitId={documentUnitId}
          fileProps={fileProps}
        />
        {documentProperties && (
          // <Virtualizer
          //   key={virtualizerKey}
          //   pageNumber={scrollToPage}
          //   fileUrl={documentUrl}
          //   pageCount={documentProperties.pageCount}
          //   pageMetadata={documentProperties.pageProperties}
          //   setCurrentPage={setCurrentPage}
          //   scale={(scale / 100) * documentProperties.scaleFactor}
          //   onLoadSuccess={() => setVirtualizerLoaded(true)}
          //   onSearch={onSearch}
          //   onExplain={onExplain}
          //   fileProps={filePropsRef.current}
          // />
          <TextViewer
            key={virtualizerKey}
            documentId={documentId}
            documentUnitId={documentUnitId}
            fileModifications={filePropsRef.current}
            documentProperties={documentProperties}
            onLoadSuccess={() => setVirtualizerLoaded(true)}
            zoom={(scale / 100) * documentProperties.scaleFactor}
          />
        )}
        {(!virtualizerLoaded || !documentProperties) && (
          <div className="absolute bg-white top-[3.5rem] bottom-0 left-0 right-0 flex items-center justify-center">
            <Icons.LogoText className="h-6 animate-pulse" />
          </div>
        )}
      </div>
    </>
  );
};

export default PDFReader;
